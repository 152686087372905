<template>
    <div>
      <header-slot>
      </header-slot>
      <b-nav card-header pills class="m-0">
        <b-nav-item
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          exact
          :to="{ name: 'sn-schedule' }"
          >SCHEDULE
        </b-nav-item>
        <b-nav-item
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          exact
          :to="{ name: 'sn-weekly-activities' }"
          >WEEKLY ACTIVITIES
        </b-nav-item>
      </b-nav>
  
      <b-card
        no-body
        class="border-top-primary border-3 border-table-radius px-0"
      >
        <router-view :key="$route.name"/>
      </b-card>
    </div>
  </template>
  
  <script>
  import { mapState, mapGetters, mapActions } from "vuex";
  export default {
    components: {
        //
    },
    data() {
      return {
        //
      };
    },
    methods: {
      //
    },
  };
  </script>
  